import React from 'react';
import { SearchOutlined, PushpinOutlined } from "@ant-design/icons";
import { Button, Space, Input } from "antd";
import get from 'lodash/get';

/**
 * Función que filtra búsquedas tanto en select simple como en select multiple
 * @param {String} inputValue El valor del input de búsqueda
 * @param {*} option Todas las opciones del select, una por una
 * @returns 
 */
function handleFilter(inputValue, option) {
  if (!inputValue) return true;
  if (!option.name) return;
  const val = inputValue.toString().toLowerCase()
  return (
    option.name.toString().toLowerCase().includes(val) ||
    (option.description !== undefined && option.description.toLowerCase().includes(val))
  );
};

/**
 * Método que permite el filtrado por texto de las columnas de una tabla a las que se agrega
 * @param {*} dataIndex Nombre de la columna
 * @returns 
 */
const GetColumnLiteralSearchProps = (dataIndex, lang) => {
  let searchInput;

  return ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { searchInput = node; }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={e => { setSelectedKeys(e.target.value ? [e.target.value] : []) }}
          onPressEnter={() => { confirm() }}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button type="primary" onClick={() => { confirm() }} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>{ lang.handle("Buscar") }</Button>
          <Button onClick={() => { clearFilters(); confirm() }} size="small" style={{ width: 90 }}>{ lang.handle("Borrar") }</Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => filtered ? <PushpinOutlined style={{ color: '#1890ff', fontSize: "1.3em" }} /> : <SearchOutlined />,
    onFilter: (value, record) => get(record, dataIndex) ? get(record, dataIndex).toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownOpenChange: visible => { if (visible) { setTimeout(() => searchInput.select(), 100); } },
  });
};

export { GetColumnLiteralSearchProps, handleFilter }
