import React from 'react';
import { notification, Input, Button, Space, Tag } from 'antd';
import { SearchOutlined, DeleteOutlined, FormOutlined, UserSwitchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import "./UsersList.scss";
import service from '../../services/Service';
import { hasPrivilege } from '../../api/token';
import { withLocale } from '../../l10n';
import { impersonate } from "../../api/user";
import DataTable from '../DataTable/DataTable';

class UserList extends React.Component {
  state = {
    data: [],
    tenantsFilter: [],
    botonDisabled: true,
    loading: false,
    update: false,
    tenants: [],
    roles: [],
    lang: this.props.lang,
  };

  getColumnSearchProps = (dataIndex, lang) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {lang.handle('Buscar')}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {lang.handle('Borrar')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  async componentDidMount() {
    this.setState({ loading: true });
    await service.get('tenants')
      .then(async res => {
        var filter = [];
        res.data.forEach(tenant => {
          filter.push({ text: tenant.name, value: tenant.uuid })
        });

        this.setState({
          tenants: res.data,
          tenantsFilter: filter
        })

        await service
          .get("roles")
          .then(res => {
            this.setState({
              roles: res.data
            })
            this.fetch();
          })
          .catch((err) => {
            console.log("Error devuelto: " + err);
          });
      });
  }
  componentDidUpdate() {
    if (this.state.update) {
      this.setState({ update: false });
      this.fetch();
    }
  }

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    service.get('users/users')
      .then(res => {
        let temp_data = [];
        if (res.data.ok === false) {
          notification["error"]({
            message: this.props.lang.handle(res.data.message),
          });
          temp_data = [];
        } else {
          temp_data = res.data;
        }

        temp_data.forEach(element => {
          const tenant = this.state.tenants.find((item) => item.uuid === element.tenant_uuid);
          if (element.tenant_uuid && tenant) {
            element.tenant_name = tenant.name;
          } else {
            element.tenant_name = this.props.lang.handle("Sin organización");
          }
        });
        this.setState({
          loading: false,
          data: temp_data,
        });
      });
  };

  deleteUser = async () => {
    const { lang } = this.state;
    this.setState({ loading: true });
    await service.del(
      "users/deleteUser/" + this.state.selectedUuid,
      this.state.selectedUuid
    )
      .then((res) => {
        if (res.data.ok === false) {
          notification["error"]({
            message: lang.handle(res.data.message),
          });
        } else {
          notification["success"]({
            message: lang.handle('Usuario borrado correctamente'),
          });
        }

        this.setState({
          loading: true,
          update: true,
        });
      })
      .catch((err) => {
        console.log("Error devuelto: " + err);
      });
  };

  impersonateUser = async () => {
    const result = await impersonate(this.state.selectedUuid);
    if (!result.ok) {
      notification["error"]({
        message: this.props.lang.handle(result.message),
      });
    }
  };

  changePassword = async () => {
    this.setState({ loading: true });
    window.location.href = '/users/changepassword/' + this.state.selectedUuid;
  }

  updateUser = async () => {
    this.setState({ loading: true });
    window.location.href = '/users/edit/' + this.state.selectedUuid;
  }

  getRoleTag = (roleUuid) => {
    const role_uuid = this.state.roles.find(el => el.uuid === roleUuid);
    return role_uuid.name;
  }

  render() {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          botonDisabled: false,
          selectedUuid: selectedRows[0].uuid,
        });
      },

      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    const { lang } = this.state;

    const columns = [
      {
        title: lang.handle("Nombre de usuario"),
        dataIndex: 'username',
        ...this.getColumnSearchProps('username', lang),
      },
      {
        title: lang.handle('Rol'),
        dataIndex: 'role_uuid',
        render: (value) => <Tag>{this.getRoleTag(value)}</Tag>,
      },
      {
        title: lang.handle('Organización'),
        dataIndex: 'tenant_name',
        filters: this.state.tenantsFilter,
        onFilter: (value, record) => record.tenant_uuid === value,
      },
      {
        title: lang.handle('Nombre'),
        dataIndex: 'name',
        ...this.getColumnSearchProps('name',lang),
      },
      {
        title: lang.handle('Apellidos'),
        dataIndex: 'lastname',
        ...this.getColumnSearchProps('lastname',lang),
      },
      {
        title: lang.handle('Email'),
        dataIndex: 'email',
        ...this.getColumnSearchProps('email',lang),
      },
    ];
    const { data, loading } = this.state;
    return (
      <div>
        <DataTable
          rowSelection={{
            type: "radio",
            ...rowSelection
          }}
          columns={columns}
          dataSource={data}
          rowKey="uuid"
          loading={loading}
        />
        <div className="button-panel">
          <Space>
            {hasPrivilege('users_edit') && <Button
              type="primary"
              icon={<FormOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.changePassword}
            >
              {lang.handle('Cambiar contraseña')}
            </Button>}
            {hasPrivilege('users_edit') && <Button
              type="primary"
              icon={<FormOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.updateUser}
            >
              {lang.handle('Editar')}
            </Button>}
            {hasPrivilege('users_delete') && <Button
              type="primary"
              icon={<DeleteOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.deleteUser}
            >
              {lang.handle('Eliminar')}
            </Button>}
            {hasPrivilege('impersonate') && <Button
              type="primary"
              icon={<UserSwitchOutlined /> }
              disabled={this.state.botonDisabled}
              onClick={this.impersonateUser}
            >
              {lang.handle('Personificar')} {this.canImpersonate}
            </Button>}
          </Space>
        </div>
      </div>
    );
  }
}

export default withLocale(UserList);
