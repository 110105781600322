import React, { Component } from "react";
import "./PasswordCard.scss";
import service from "../../services/Service";
import { notification } from "antd";
import { withLocale } from '../../l10n';
class PasswordCard extends Component {
  // Preinicializamos el estado de la clase
  state = {
    uuid: "",
    username: "",
    password: "",
    repeatPassword: "",
    errors: {
      password: '',
    },
    lang: this.props.lang
  };

  // Funcion que guarda el valor introducido en el formulario dentro del campo correspondiente de state
  onInputChange = (e) => {
    const { name, value } = e.target;
    const { lang } = this.state;
    let errors = this.state.errors;
    switch (name) {
      case 'password':
        errors.password = value === this.state.repeatPassword
          ? ''
          : lang.handle('¡Las contraseñas deben coincidir!');
        break;
      case 'repeatPassword':
        errors.password = this.state.password === value
          ? ''
          : lang.handle('¡Las contraseñas deben coincidir!');
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value })
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const { lang } = this.state;
    const userData = this.state;
    const errors = this.state.errors;
    if (userData.password === "" || userData.repeatPassword === "") {
      //La notificación funciona, siempre que se encuentre traducción.
      notification["error"]({
        message: lang.handle('La contraseña es un campo obligatorio'),
      });
      return;
    }
    if (!errors.password) {
      await service
        .put("users/changepassword/" + this.state.uuid, userData)
        .then((res) => {
          if (res.data.ok === false) {
            notification["error"]({
              message: lang.handle(res.data.message),
            });
            return;
          } else {
            notification["success"]({
              message: lang.handle('Contraseña actualizada correctamente'),
            });
            window.location.href = "/users/list";
          }
        })
        .catch((err) => {
          console.log("Error devuelto: " + err);
        });
    } else {
      if (errors.password) {
        notification["error"]({
          message: lang.handle('Las contraseñas deben coincidir'),
        });
      }
    }
  };

  async componentDidMount() {
    const uuid = this.props.ident;
    await service.get("users/users/" + uuid).then((res) => {
      if (res.data.ok === false) {
        notification["error"]({
          message: this.props.lang.handle(res.data.message),
        });
        return;
      }
      if (!res.data[0]) {
        return;
      }
      this.setState({
        uuid: res.data[0].uuid,
        password: res.data[0].password,
        username: res.data[0].username,
      });
    });

  }

  render() {

    const {lang} = this.state;

    // Funcion que crea el boton de guardado
    function Save({ save }) {
      if (save === true) {
        return (
          /* Boton del formulario */
          <button
            id="button-save"
            className="btn btn-primary d-flex float-right"
            type="submit"
          >
            <div className="btnText">
              <p> {lang.handle('Guardar')}&nbsp;&nbsp; </p>
              <i className="material-icons"> save </i>
            </div>
          </button>
        );
      } else {
        return null;
      }
    }

    const { errors } = this.state;

    return (
      <div className="card card-body">
        <h4> {lang.handle('Cambio de contraseña usuario') + ":"} {this.state.username} </h4>
        <form onSubmit={this.onSubmit}>
          <div className="form-group row">
            <label
              htmlFor="password"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              {lang.handle('Contraseña')}{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="password"
                className="form-control"
                id="password"
                onChange={this.onInputChange}
                name="password"
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="repeatPassword"
              className="col-sm-2 col-form-label newCard-form-text"
            >
              {" "}
              {lang.handle('Repetir contraseña')}{" "}
            </label>
            <div className="col-sm-10">
              <input
                type="password"
                className="form-control"
                id="repeatPassword"
                onChange={this.onInputChange}
                name="repeatPassword"
              />
              {errors.password.length > 0 && <span className='boardFormError'>{errors.password}</span>}
            </div>
          </div>
          <Save save={this.props.editable} />
        </form>
      </div>
    );
  }
}
export default withLocale(PasswordCard);
