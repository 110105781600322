import { version as uuidVersion } from 'uuid';
import { validate as uuidValidate } from 'uuid';

export function uuidValidateV4(uuid) {
  return uuidValidate(uuid) && uuidVersion(uuid) === 4;
}

export function uuidValidation(inputData) {
  // eslint-disable-next-line no-useless-escape
  const emailValid = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;


  const resultValidation = emailValid.test(inputData);
  if (resultValidation || !inputData) {
    return true;
  } else {
    return false;
  }
}

export function macValidation(inputData) {
  // eslint-disable-next-line no-useless-escape
  const emailValid = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;


  const resultValidation = emailValid.test(inputData);
  if (resultValidation || !inputData) {
    return true;
  } else {
    return false;
  }
}

export function ssidValidation(inputData) {
  // eslint-disable-next-line no-useless-escape
  const ssidValid = /^(KIWI_)([0-9A-Fa-f]{6})$/;


  const resultValidation = ssidValid.test(inputData);
  if (resultValidation || !inputData) {
    return true;
  } else {
    return false;
  }
}

export function emailValidation(inputData) {
  // eslint-disable-next-line no-useless-escape
  const emailValid = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const { value } = inputData;


  const resultValidation = emailValid.test(value);
  if (resultValidation) {
    return true;
  } else {
    return false;
  }
}

export function lengthValidation(inputData, maxLength, minLength = 0) {
  const input = inputData.toString();
  return input.length <= maxLength && input.length >= minLength;
}

export function typeValidation(inputData, typeRequired) {
  switch (typeRequired) {
    case 'number':
      return !isNaN(Number(inputData));
    default:
      return true;
  }
}
