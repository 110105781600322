import React from 'react';
import { notification, Input, Button, Space } from 'antd';
import { SearchOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import "./KiwiList.scss";
import { hasPrivilege } from "../../api/token";
import service from "../../services/Service"
import moment from 'moment';
import { withLocale } from '../../l10n';
import DataTable from '../DataTable/DataTable';
class KiwiList extends React.Component {
  state = {
    datos: [],
    loading: false,
    botonDisabled: true,
    selectedId: '',
    lang: this.props.lang,
  };

  getColumnSearchProps = (dataIndex, lang) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {lang.handle('Buscar')}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            {lang.handle('Borrar')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  getListData = async () => {
    const params = {
      tenant: this.props.userTenant,
    }

    this.setState({ loading: true });
    await service.get('kiwi', params)
      .then(res => {
        if (res.data.ok === false) {
          notification["error"]({
            message: this.props.lang.handle(res.data.message),
          });
          this.setState({
            datos: [],
          });
        } else {
          this.setState({
            datos: res.data,
          });
        }
        this.setState({
          loading: false,
        });
      })
      .catch(err => {
        console.log('Error devuelto: ' + err);
      })
  }

  async componentDidMount() {
    this.getListData();
  }

  deletePlaca = async () => {
    const { lang } = this.state;
    this.setState({ loading: true });
    await service.del('kiwi/' + this.state.selectedId, this.state.selectedId)
      .then(res => {
        if (res.data.ok === false) {
          notification["error"]({
            message: lang.handle(res.data.message),
          });
        } else {
          notification["success"]({
            message: lang.handle('Placa borrada correctamente'),
          });
        }
        this.getListData()
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log('Error devuelto: ' + err);
      })
  };

  updatePlaca = () => {
    this.setState({ loading: true });
    window.location.href = '/boards/edit/2/' + this.state.selectedId;
  };

  render() {

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          botonDisabled: false,
          selectedId: selectedRows[0].uuid,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    const { lang } = this.state;
    const columns = [
      // { uuid, hardware_version, mac, date, oem, model, usb, subscription, devices_number, icc, optionals, notes }
      {
        title: lang.handle('Pedido'),
        dataIndex: 'order_id',
        ...this.getColumnSearchProps('order_id', lang),
        //key:'_id',
      },
      {
        title: lang.handle('Número de serie'),
        dataIndex: 'serial_number',
        ...this.getColumnSearchProps('serial_number', lang),
      },
      {
        title: lang.handle('Orden de trabajo'),
        dataIndex: 'work_order',
        ...this.getColumnSearchProps('work_order', lang),
      },
      {
        title: 'SSID',
        dataIndex: 'ssid',
        ...this.getColumnSearchProps('ssid', lang),
      },
      {
        title: 'MAC',
        dataIndex: 'mac',
        ...this.getColumnSearchProps('mac', lang),
      },
      {
        title: lang.handle('Fecha fabricación'),
        dataIndex: 'date',
        defaultSortOrder: 'descend',
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
        sortDirections: ['descend', 'ascend'],
        render: function (data) {
          var d = new Date(data);
          if (d !== 'Invalid Date') {
            data = moment(d).format('DD-MM-YYYY');
          }
          return <p> {data} </p>
        }
      },
      {
        title: lang.handle('UUID placa'),
        dataIndex: 'board_uuid',
        ...this.getColumnSearchProps('board_uuid', lang),
      },
      {
        title: lang.handle('Notas'),
        dataIndex: 'notes',
      },
      {
        title: lang.handle('Software'),
        dataIndex: 'software',
      },
      {
        title: lang.handle('Estado'),
        dataIndex: 'state',
        filters: [
          { text: lang.handle('Fabricado'), value: 'Fabricado' },
          { text: lang.handle('Asignado'), value: 'Asignado' },
          { text: lang.handle('Terminado'), value: 'Terminado' },
          { text: lang.handle('Eliminado'), value: 'Eliminado' },
        ],
        onFilter: (value, record) => record.state && record.state.toLowerCase() === value.toLowerCase()
      }
    ];
    const { datos, loading } = this.state;

    let selection = null;
    if (hasPrivilege('administration_delete') || hasPrivilege('administration_edit')) {
      selection =
      {
        type: 'radio',
        ...rowSelection,
      };
    }

    return (
      <div>
        <DataTable
          rowSelection={selection}
          columns={columns}
          rowKey="uuid"
          dataSource={datos}
          loading={loading}
        />
        <div className="button-panel">
          <Space>
            {hasPrivilege('administration_delete') && <Button
              type="primary"
              icon={<DeleteOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.deletePlaca}
            >
              {lang.handle('Eliminar')}
            </Button>}
            {hasPrivilege('administration_edit') && <Button
              type="primary"
              icon={<FormOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.updatePlaca}
            >
              {lang.handle('Editar')}
            </Button>}
          </Space>
        </div>
      </div>
    );
  }
}

export default withLocale(KiwiList);
