exports.USER_PRIVILEGES = {
  'Manufacture': {
    label: 'Fábrica',
    name: 'manufacture',
    description: 'Gestión de placas de fábrica',
    privileges: {
      'manufacture_view': {
        name: 'manufacture_view',
        description: 'Ver placas de fábrica',
        label: 'Ver',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'manufacture_create': {
        name: 'manufacture_create',
        description: 'Crear placa de fábrica',
        label: 'Crear',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'manufacture_edit': {
        name: 'manufacture_edit',
        description: 'Editar placa de fábrica',
        label: 'Editar',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'manufacture_delete': {
        name: 'manufacture_delete',
        description: 'Borrar placa de fábrica',
        label: 'Borrar',
        active: false,
        sec_principals: {},
        uuid: null,
      },
    },
  },
  'Assign': {
    label: 'Asignaciones de fábrica',
    name: 'assign',
    description: 'Asignación de placas de fábrica',
    privileges: {
      'assign_view': {
        name: 'assign_view',
        description: 'Ver placas asignadas de fábrica',
        label: 'Ver',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'assign': {
        name: 'assign',
        description: 'Asignar placas de fábrica',
        label: 'Asignar',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'assign_finish': {
        name: 'assign_finish',
        description: 'Finalizar placas asignadas de fábrica',
        label: 'Finalizar',
        active: false,
        sec_principals: {},
        uuid: null,
      },
    },
  },
  'Administration': {
    label: 'Administración',
    name: 'administration',
    description: 'Gestión de placas de administración',
    privileges: {
      'administration_view': {
        name: 'administration_view',
        description: 'Ver placas de administración',
        label: 'Ver',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'administration_create': {
        name: 'administration_create',
        description: 'Crear placa de administración',
        label: 'Crear',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'administration_edit': {
        name: 'administration_edit',
        description: 'Editar placa de administración',
        label: 'Editar',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'administration_edit_comercial': {
        name: 'administration_edit_comercial',
        description: 'Editar zona comercial en placa de administración',
        label: 'Editar comercial',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'administration_delete': {
        name: 'administration_delete',
        description: 'Borrar placa de administración',
        label: 'Borrar',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'administration_renewal': {
        name: 'administration_renewal',
        description: 'Ver renovaciones pendientes de las placas',
        label: 'Ver renovaciones',
        active: false,
        sec_principals: {},
        uuid: null,
      },
    },
  },
  'Orders': {
    label: 'Pedidos',
    name: 'orders',
    description: 'Gestión de pedidos',
    privileges: {
      'orders_view': {
        name: 'orders_view',
        description: 'Ver pedidos',
        label: 'Ver',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'orders_change_status': {
        name: 'orders_change_status',
        description: 'Cambiar estado de pedidos',
        label: 'Cambiar Estado',
        active: false,
        sec_principals: {},
        uuid: null,
      },
    },
  },
  'mykiconex': {
    label: 'mykiconex',
    name: 'mykiconex',
    description: 'Gestión con plataforma mykiconex',
    privileges: {
      'mykiconex_view': {
        name: 'm2m_view',
        description: 'Ver listado de instalaciones kiconex',
        label: 'Ver',
        active: false,
        sec_principals: {},
        uuid: null,
      },
    },
  },
  'M2M': {
    label: 'M2M',
    name: 'm2m',
    description: 'Gestión de tarjetas M2M',
    privileges: {
      'm2m_view': {
        name: 'm2m_view',
        description: 'Ver listado M2M',
        label: 'Ver M2M',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'm2m_cicle': {
        name: 'm2m_cicle',
        description: 'Cambiar ciclo de vida M2M',
        label: 'Cambiar',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'm2m_state': {
        name: 'm2m_state',
        description: 'Ver estado M2M',
        label: 'Ver estado',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'pools_view': {
        name: 'pools_view',
        description: 'Ver listado pools',
        label: 'Ver pools',
        active: false,
        sec_principals: {},
        uuid: null,
      },
    },
  },
  'VPN': {
    label: 'VPN (antigua)',
    name: 'vpn',
    description: 'Ver acceso a VPN (antigua)',
    privileges: {
      'vpn_view_old': {
        name: 'vpn_view_old',
        description: 'Ver VPN (antigua)',
        label: 'Ver',
        active: false,
        sec_principals: {},
        uuid: null,
      },
    },
  },
  'Support': {
    label: 'Soporte',
    name: 'support',
    description: 'Ver acceso a soporte',
    privileges: {
      'support_view': {
        name: 'support_view',
        description: 'Ver soporte',
        label: 'Ver',
        active: false,
        sec_principals: {},
        uuid: null,
      },
    },
  },
  'Chat': {
    label: 'Chat',
    name: 'chat',
    description: 'Ver acceso a chat',
    privileges: {
      'chat_view': {
        name: 'chat_view',
        description: 'Ver chat',
        label: 'Ver',
        active: false,
        sec_principals: {},
        uuid: null,
      },
    },
  },
  'Users': {
    label: 'Usuarios',
    name: 'users',
    description: 'Gestión de usuarios',
    privileges: {
      'users_view': {
        name: 'users_view',
        description: 'Ver usuarios',
        label: 'Ver',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'users_create': {
        name: 'users_create',
        description: 'Crear usuarios',
        label: 'Crear',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'users_edit': {
        name: 'users_edit',
        description: 'Editar usuarios',
        label: 'Editar',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'users_delete': {
        name: 'users_delete',
        description: 'Borrar usuarios',
        label: 'Borrar',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'roles_settings': {
        name: 'roles_settings',
        description: 'Gestión de roles',
        label: 'Roles',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'impersonate': {
        name: 'impersonate',
        description: 'Personificar',
        label: 'Personificar',
        active: false,
        sec_principals: {},
        uuid: null,
      },
    },
  },
  'Sistema': {
    label: 'Sistema',
    name: 'system',
    description: 'Gestión de sistema',
    privileges: {
      'privileges_view': {
        name: 'privileges_view',
        description: 'Ver privilegios',
        label: 'Ver privilegios',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'privileges_edit': {
        name: 'privileges_edit',
        description: 'Editar privilegios',
        label: 'Editar privilegios',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'vpn_view': {
        name: 'vpn_view',
        description: 'Ver VPN',
        label: 'Ver VPN',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'vpn_create': {
        name: 'vpn_create',
        description: 'Crear VPN',
        label: 'Crear VPN',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'vpn_edit': {
        name: 'vpn_edit',
        description: 'Editar VPN',
        label: 'Editar VPN',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'info_view': {
        name: 'info_view',
        description: 'Ver información',
        label: 'Ver información',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'activity_view': {
        name: 'activity_view',
        description: 'Ver actividad',
        label: 'Ver actividad',
        active: false,
        sec_principals: {},
        uuid: null,
      },
      'settings': {
        name: 'settings',
        description: 'Ajustes',
        label: 'Ajustes',
        active: false,
        sec_principals: {},
        uuid: null,
      },
    },
  },
}
