import React from 'react';
import { notification, Input, Button, Space, Modal } from 'antd';
import { SearchOutlined, PlusOutlined, KeyOutlined, CopyOutlined, FormOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import "./VPN.scss";
import service from '../../services/Service';
import { hasPrivilege } from '../../api/token';
import {
  uuidValidation
} from "../../utils/formValidation";
import { withLocale } from '../../l10n';
import DataTable from '../DataTable/DataTable';

class VPN extends React.Component {
  state = {
    data: [],
    tenantsFilter: [],
    botonDisabled: true,
    loading: false,
    tenants: [],
    lang: this.props.lang,
    showModal: false,
    uuid: null,
    password: null,
    isNew: false
  };

  getColumnSearchProps = (dataIndex, lang) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {lang.handle('Buscar')}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            {lang.handle('Borrar')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  async componentDidMount() {
    this.setState({ loading: true });
    await service.get('tenants')
      .then(async res => {
        var filter = [];
        res.data.forEach(tenant => {
          filter.push({ text: tenant.name, value: tenant.uuid })
        });

        this.setState({
          tenants: res.data,
          tenantsFilter: filter
        })

        this.fetch();
      });
  }

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    service.get('vpn')
      .then(res => {
        let temp_data = [];
        if (res.data.ok === false) {
          notification["error"]({
            message: this.props.lang.handle(res.data.message),
          });
          temp_data = [];
        } else {
          temp_data = res.data;
        }

        temp_data.forEach(element => {
          const tenant = this.state.tenants.find((item) => item.uuid === element.tenant_uuid);
          if (element.tenant_uuid && tenant) {
            element.tenant_name = tenant.name;
          } else {
            element.tenant_name = this.props.lang.handle("Sin organización");
          }
        });
        this.setState({
          loading: false,
          data: temp_data,
        });
      });
  };

  createVPN = async () => {
    this.generateRandomPassword();
    this.setState({ isNew: true, uuid: null, showModal: true });
  }

  changePassword = async () => {
    this.generateRandomPassword();
    this.setState({ uuid: this.state.selectedUuid, showModal: true });
  }

  handleCancel = () => {
    this.setState({ isNew: false, showModal: false });
  };

  onSubmit = async () => {
    const { uuid, password, isNew } = this.state;
    const lang = this.props.lang;
    if (uuid && uuidValidation(uuid)) {
      if (isNew) {
        await service
          .post("vpn", { uuid, password })
          .then((res) => {
            if (res.data.ok === false) {
              notification["error"]({
                message: lang.handle(res.data.message),
              });
              return;
            } else {
              notification["success"]({
                message: lang.handle('VPN creada correctamente'),
              });
              this.setState({ showModal: false, isNew: false });
            }
          })
          .catch((err) => {
            console.log("Error devuelto: " + err);
          });
      } else {
        await service
          .put("vpn/" + uuid, { password })
          .then((res) => {
            if (res.data.ok === false) {
              notification["error"]({
                message: lang.handle(res.data.message),
              });
              return;
            } else {
              notification["success"]({
                message: lang.handle('VPN actualizada correctamente'),
              });
              this.setState({ showModal: false });
            }
          })
          .catch((err) => {
            console.log("Error devuelto: " + err);
          });
      }
      this.fetch();
    } else {
      notification["error"]({
        message: lang.handle('¡UUID erróneo!'),
      });
    }
  };

  onInputChange = (e) => {
    const { value } = e.target;
    this.setState({ uuid: value })
  }

  generateRandomPassword = () => {
    const length = 8; // You can adjust the length of the password here
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    this.setState({ password: result });
  };

  copyToClipboard = () => {
    navigator.clipboard.writeText(this.state.password).then(() => {
      notification["success"]({
        message: this.props.lang.handle('Contraseña copiada al portapapeles'),
      });
    }).catch(err => {
      console.log(err)
      notification["error"]({
        message: this.props.lang.handle('Fallo al copiar la contraseña'),
      });
    });
  };

  render() {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          botonDisabled: false,
          selectedUuid: selectedRows[0].username,
        });
      }
    };

    const { lang } = this.state;

    const columns = [
      {
        title: "UUID",
        dataIndex: 'username',
        ...this.getColumnSearchProps('username', lang)
      },
      {
        title: 'IP',
        dataIndex: 'framedipaddress',
        ...this.getColumnSearchProps('framedipaddress', lang)
      }
    ];
    const { data, loading, password, uuid, isNew } = this.state;
    return (
      <div>
        {hasPrivilege('vpn_create') &&
          <Button
            className='new'
            type="primary"
            icon={<PlusOutlined />}
            onClick={this.createVPN}
          >
            {lang.handle('Nueva conexión VPN')}
          </Button>
        }
        <DataTable
          rowSelection={{
            type: "radio",
            ...rowSelection
          }}
          columns={columns}
          dataSource={data}
          rowKey="username"
          loading={loading}
          scroll={null}
        />
        <div className="button-panel">
          <Space>
            {hasPrivilege('vpn_edit') && <Button
              type="primary"
              icon={<FormOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.changePassword}
            >
              {lang.handle('Regenerar contraseña')}
            </Button>}
          </Space>
        </div>

        <Modal
          title={lang.handle('Contraseña VPN')}
          onCancel={this.handleCancel}
          cancelText={lang.handle('Cerrar')}
          onOk={this.onSubmit}
          okText={lang.handle('Guardar')}
          icon={<KeyOutlined />}
          open={this.state.showModal}
          width={600}
        >
          {lang.handle('Copie la contraseña antes de guardar, no volverá a ser visible')}
          <form onSubmit={this.onSubmit} className="p-3">
            <div className="form-group row">
              <label htmlFor="uuid"> {lang.handle('UUID')} </label>
              <Input name='uuid' value={uuid} className='col' onChange={this.onInputChange} readOnly={!isNew} />
            </div>

            <div className="form-group row">
              <label htmlFor="password"> {lang.handle('Contraseña')} </label>
              <Input.Password name='password' value={password} readOnly className='col' />
              <Button onClick={this.copyToClipboard} disabled={password === null} className='col-2'><CopyOutlined /></Button>
            </div>
          </form>

        </Modal>
      </div>
    );
  }
}

export default withLocale(VPN);
