import React from "react";
import { notification, Input, Button, Space } from "antd";
import {
  SearchOutlined,
  DeleteOutlined,
  FormOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import "./KiboxList.scss";
import { hasPrivilege } from "../../api/token";
import service from "../../services/Service";
import moment from "moment";
import FilterColumns from "../FilterColumns";
import get from 'lodash/get';
import isequal from 'lodash/isEqual';
import { withLocale } from "../../l10n";
import DataTable from "../DataTable/DataTable";

class KiboxList extends React.Component {
  state = {
    columns: [],
    tenantsFilter: [],
    datos: [],
    loading: false,
    botonDisabled: true,
    selectedId: "",
    tenants: [],
    lang: this.props.lang
  };

  getColumnSearchProps = (dataIndex, lang) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {lang.handle('Buscar')}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {lang.handle('Borrar')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      get(record, dataIndex)
        ? get(record, dataIndex)
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      isequal(this.state.searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getListData = async () => {
    this.setState({ loading: true });
    await service
      .get('boards')
      .then((res) => {
        let data = [];

        if (res.data.ok === false) {
          notification["error"]({
            message: this.props.lang.handle(res.data.message),
          });
        } else {
          data = res.data;
        }
        data.forEach(element => {
          const tenant = this.state.tenants.find((item) => item.uuid === element.tenant_uuid);
          if (element.tenant_uuid && tenant) {
            element.tenant_name = tenant.name;
          } else {
            element.tenant_name = this.props.lang.handle("Sin organización");
          }
        });
        this.setState({
          loading: false,
          datos: data,
        });
      })
      .catch((err) => {
        console.log("Error devuelto: " + err);
      });
  };

  allColumns = [
    {
      title: 'Id',
      dataIndex: "ki_id",
      ...this.getColumnSearchProps("ki_id", this.props.lang),
    },
    {
      title: this.props.lang.handle('Pedido'),
      dataIndex: "order_id",
      ...this.getColumnSearchProps("order_id", this.props.lang),
      //key:'_id',
    },
    {
      title: this.props.lang.handle('Instalacion'),
      dataIndex: "name",
      ...this.getColumnSearchProps("name", this.props.lang),
    },
    {
      title: this.props.lang.handle('Organización'),
      dataIndex: "tenant_name",
      filters: this.state.tenantsFilter,
      onFilter: (value, record) => record.tenant_uuid === value,
    },
    {
      title: this.props.lang.handle('Cliente final'),
      dataIndex: "final_client",
      ...this.getColumnSearchProps("final_client", this.props.lang),
    },
    {
      title: this.props.lang.handle('UUID'),
      dataIndex: "uuid",
      ...this.getColumnSearchProps("uuid", this.props.lang),
    },
    {
      title: this.props.lang.handle('Número de serie'),
      dataIndex: "serial_number",
      ...this.getColumnSearchProps("serial_number", this.props.lang),
    },
    {
      title: this.props.lang.handle('Orden de trabajo'),
      dataIndex: "work_order",
      ...this.getColumnSearchProps("work_order", this.props.lang),
    },
    {
      title: this.props.lang.handle('Nombre comercial'),
      dataIndex: ["comercial_info", "name"],
      ...this.getColumnSearchProps(["comercial_info", "name"], this.props.lang),
    },
    {
      title: this.props.lang.handle('Pedido comercial'),
      dataIndex: ["comercial_info", "order_id"],
      ...this.getColumnSearchProps(["comercial_info", "order_id"], this.props.lang),
    },
    {
      title: this.props.lang.handle('Número de serie comercial'),
      dataIndex: ["comercial_info", "serial_number"],
      ...this.getColumnSearchProps(["comercial_info", "serial_number"], this.props.lang),
    },
    {
      title: this.props.lang.handle('Modelo del equipo'),
      dataIndex: ["comercial_info", "model"],
      ...this.getColumnSearchProps(["comercial_info", "model"], this.props.lang),
    },
    {
      title: this.props.lang.handle('Estado Comercial'),
      dataIndex: ["comercial_info", "state"],
      filters: [
        { text: this.props.lang.handle("Stock"), value: "Stock" },
        { text: this.props.lang.handle('Asignado'), value: 'Asignado' },
        { text: this.props.lang.handle('Terminado'), value: 'Terminado' },
      ],
      onFilter: (value, record) =>
        record.comercial_info && record.comercial_info.state && record.comercial_info.state.toLowerCase() === value.toLowerCase(),
    },
    {
      title: this.props.lang.handle('kiconex plan'),
      dataIndex: "ki_subscription_name",
      ...this.getColumnSearchProps("ki_subscription_name", this.props.lang),
    },
    {
      title: this.props.lang.handle('Fecha fabricación'),
      dataIndex: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      sortDirections: ["descend", "ascend"],
      render: function (data) {
        var d = new Date(data);
        if (d !== "Invalid Date") {
          data = moment(d).format('DD-MM-YYYY');
        }
        return <p> {data} </p>;
      },
    },
    {
      title: this.props.lang.handle('Fecha Conexión'),
      dataIndex: "ki_connection_date",
      sorter: (a, b) => new Date(a.ki_connection_date) - new Date(b.ki_connection_date),
      sortDirections: ["descend", "ascend"],
      render: function (data) {
        if (data === null) {
          return null;
        }
        var d = new Date(data);
        if (d !== "Invalid Date") {
          data = moment(d).format('DD-MM-YYYY');
        }
        return <p> {data} </p>;
      },
    },
    {
      title: this.props.lang.handle('Version hardware'),
      dataIndex: "hardware_version",
    },
    {
      title: "MAC",
      dataIndex: "mac",
      ...this.getColumnSearchProps("mac", this.props.lang),
    },
    {
      title: "OEM",
      dataIndex: "oem",
      filters: [
        { text: this.props.lang.handle('No'), value: 0 },
        { text: this.props.lang.handle('Sí'), value: 1 },
      ],
      onFilter: (value, record) =>
        (record.oem || record.oem === 0) && record.oem === value,
    },
    {
      title: this.props.lang.handle('Modelo'),
      dataIndex: "model",
      filters: [
        { text: "Kibox0", value: "Kibox0" },
        { text: "Kibox1", value: "Kibox1" },
        { text: "Kibox1A", value: "Kibox1A" },
        { text: "Kibox1E", value: "Kibox1E" },
        { text: "Kibox2", value: "Kibox2" },
        { text: "Kibox2A", value: "Kibox2A" },
        { text: "Kibox4", value: "Kibox4" },
        { text: "Kibox8", value: "Kibox8" },
        { text: "Kibox-Lite", value: "Kibox-Lite" },
        { text: "Kibox-CAN", value: "Kibox-CAN" },
      ],
      onFilter: (value, record) => record.model && record.model.toLowerCase().toString() === value.toLowerCase().toString()
    },
    {
      title: this.props.lang.handle('Tipo USB'),
      dataIndex: "usb",
      filters: [
        { text: this.props.lang.handle('Ninguno'), value: 'Ninguno' },
        { text: this.props.lang.handle('Chino'), value: 'Chino' },
        { text: "FTDI", value: "FTDI" },
        { text: "USB-COM4", value: "USB-COM4" },
        { text: "SHIELD-V1.0.1-2P", value: "SHIELD-V1.0.1-2P" },
        { text: "SHIELD-V1.0.1-4P", value: "SHIELD-V1.0.1-4P" },
        { text: "SHIELD-V1.0.1-CAN", value: "SHIELD-V1.0.1-CAN" },
      ],
      onFilter: (value, record) =>
        record.usb && record.usb.toLowerCase() === value.toLowerCase(),
    },
    {
      title: this.props.lang.handle('Plan'),
      dataIndex: "subscription",
      filters: [
        { text: "Basic", value: "Basic" },
        { text: "Professional", value: "Professional" },
        { text: "Premium", value: "Premium" },
        { text: "OEM Keyter", value: "OEM Keyter" },
        { text: "OEM Intarcon", value: "OEM Intarcon" },
        { text: "OEM Genaq", value: "OEM Genaq" },
        { text: "OEM Infrico", value: "OEM Infrico" },
        { text: "OEM FMGrupo", value: "OEM FMGrupo" },
      ],
      onFilter: (value, record) =>
        record.subscription && record.subscription.toLowerCase() === value.toLowerCase(),
    },
    {
      title: this.props.lang.handle('Nº dispositivos'),
      dataIndex: "devices_number",
    },
    {
      title: this.props.lang.handle('Cuadro'),
      dataIndex: "panel",
      filters: [
        { text: this.props.lang.handle('No'), value: 0 },
        { text: this.props.lang.handle('Sí'), value: 1 },
      ],
      onFilter: (value, record) =>
        (record.panel || record.panel === 0) && record.panel === value,
    },
    {
      title: this.props.lang.handle('Cable') + " (m)",
      dataIndex: "cable",
    },
    {
      title: "Kiwi",
      dataIndex: "kiwi",
    },
    {
      title: "ICC",
      dataIndex: "icc",
      ...this.getColumnSearchProps("icc", this.props.lang),
    },
    {
      title: this.props.lang.handle('Opcionales'),
      dataIndex: "optionals",
      ...this.getColumnSearchProps("optionals", this.props.lang),
    },
    {
      title: this.props.lang.handle('Notas'),
      dataIndex: "notes",
      ...this.getColumnSearchProps("notes", this.props.lang),
    },
    {
      title: this.props.lang.handle('Estado'),
      dataIndex: "state",
      filters: [
        { text: this.props.lang.handle('Fabricado'), value: 'Fabricado' },
        { text: this.props.lang.handle('Asignado'), value: 'Asignado' },
        { text: this.props.lang.handle('Terminado'), value: 'Terminado' },
        { text: this.props.lang.handle('Eliminado'), value: 'Eliminado' },
      ],
      onFilter: (value, record) =>
        record.state && record.state.toLowerCase() === value.toLowerCase(),
    },
  ];

  async componentDidMount() {
    this.setState({ loading: true });

    await service.get('tenants')
      .then(res => {
        var filter = [];
        res.data.forEach(tenant => {
          filter.push({ text: tenant.name, value: tenant.uuid })
        });
        filter.push({ text: this.props.lang.handle("Sin organización"), value: null });

        // Filtro de organizaciones
        this.allColumns.find(e => e.dataIndex === "tenant_name").filters = filter;

        this.setState({
          tenants: res.data,
          tenantsFilter: filter,
          columns: this.allColumns
        })
        this.getListData();
      });
  }

  deletePlaca = async () => {
    const { lang } = this.state;
    this.setState({ loading: true });
    await service
      .del("boards/" + this.state.selectedId, this.state.selectedId)
      .then((res) => {
        if (res.data.ok === false) {
          notification["error"]({
            message: lang.handle(res.data.message),
          });
        } else {
          notification["success"]({
            message: lang.handle('Placa borrada correctamente'),
          });
        }
        this.getListData()
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log("Error devuelto: " + err);
      });
  };

  updatePlaca = () => {
    window.location.href = "/boards/edit/1/" + this.state.selectedId;
  };

  render() {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          botonDisabled: false,
          selectedId: selectedRows[0].ki_id,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    const { datos, loading, columns } = this.state;

    let selection = null;
    if (hasPrivilege('administration_delete') || hasPrivilege('administration_edit') || hasPrivilege('administration_edit_comercial')) {
      selection =
      {
        type: 'radio',
        ...rowSelection,
      };
    }

    const changeColumns = (columns) => {
      const result = columns.length === 0 ? this.allColumns : columns;
      this.setState({ columns: result });
    }

    return (
      <div>
        <div className="row">
          <div className="col">
            <FilterColumns columns={this.allColumns} changeColumns={changeColumns} />
          </div>
        </div>
        <DataTable
          rowSelection={selection}
          columns={columns}
          rowKey="ki_id"
          dataSource={datos}
          loading={loading}
        />
        <div className="button-panel">
          <Space>
            {hasPrivilege('administration_delete') && <Button
              type="primary"
              icon={<DeleteOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.deletePlaca}
            >
              {this.props.lang.handle('Eliminar')}
            </Button>}
            {(hasPrivilege('administration_edit') || hasPrivilege('administration_edit_comercial')) && <Button
              type="primary"
              icon={<FormOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.updatePlaca}
            >
              {this.props.lang.handle('Editar')}
            </Button>}
          </Space>
        </div>
      </div>
    );
  }
}
export default withLocale(KiboxList);
