import { notification } from 'antd';
import axios from 'axios';
import { API_TOKEN } from "../utils/constants";

const URL = process.env.REACT_APP_API_URL;

class Service {
  constructor(api_url) {
    this.baseUrl = api_url;
  }

  get = async (url, params = null) => {
    try {
      var config = {
        method: 'get',
        url: this.baseUrl + url,
        headers: {
          'Authorization': 'Basic ' + localStorage.getItem(API_TOKEN),
          'Content-Type': 'application/json'
        },
        params
      };
      return await axios(config)
    } catch (err) {
      notification["error"]({
        message: err.response.data,
      });
    }
  }

  post = async (url, data) => {
    try {
      var config = {
        method: 'post',
        url: this.baseUrl + url,
        headers: {
          'Authorization': 'Basic ' + localStorage.getItem(API_TOKEN),
          'Content-Type': 'application/json'
        },
        data: data
      };
      return await axios(config)
    } catch (err) {
      notification["error"]({
        message: err.response.data,
      });
    }
  }

  put = async (url, data) => {
    try {
      var config = {
        method: 'put',
        url: this.baseUrl + url,
        headers: {
          'Authorization': 'Basic ' + localStorage.getItem(API_TOKEN),
          'Content-Type': 'application/json'
        },
        data: data
      };
      return await axios(config)
    } catch (err) {
      notification["error"]({
        message: err.response.data,
      });
    }
  }

  del = async (url, id) => {
    try {
      var config = {
        method: 'delete',
        url: this.baseUrl + url,
        headers: {
          'Authorization': 'Basic ' + localStorage.getItem(API_TOKEN),
          'Content-Type': 'application/json'
        }
      };
      return await axios(config)
    } catch (err) {
      notification["error"]({
        message: err.response.data,
      });
    }
  }

}

// eslint-disable-next-line 
export default new Service(URL);
