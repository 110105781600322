import React, { Component } from "react";
import { Input, Button, Space, notification } from "antd";
import { SearchOutlined, FlagOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import "./KiboxAssignedList.scss";
import service from "../../services/Service";
import { hasPrivilege } from "../../api/token";
import moment from "moment";
import { withLocale } from "../../l10n";
import DataTable from "../DataTable/DataTable";

class KiboxAssignedList extends Component {
  state = {
    datos: [],
    loading: false,
    botonDisabled: true,
    selectedId: "",
    tenants: [],
    tenantsFilter: [],
    lang: this.props.lang
  };

  getColumnSearchProps = (dataIndex, lang) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {lang.handle('Buscar')}

          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {lang.handle('Borrar')}

          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  async componentDidMount() {
    this.setState({ loading: true });

    await service.get('tenants')
      .then(res => {
        var filter = [];
        res.data.forEach(tenant => {
          filter.push({ text: tenant.name, value: tenant.uuid })
        });

        this.setState({
          tenants: res.data,
          tenantsFilter: filter,
        })
        this.Pfetch();
      });
  }

  Pfetch = async () => {
    this.setState({ loading: true });
    await service
      .get("boards/manufacture/Asignado")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          data.forEach(element => {
            const tenant = this.state.tenants.find((item) => item.uuid === element.tenant_uuid);
            if (element.tenant_uuid && tenant) {
              element.tenant_name = tenant.name;
            } else {
              element.tenant_name = this.props.lang.handle("Sin organización");
            }
          });
          this.setState({
            loading: false,
            datos: data,
          });
        }
      })
      .catch((err) => {
        console.log("Error devuelto: " + err);
      });
  };

  finishBoard = async (e) => {
    const { lang } = this.state;
    this.setState({ loading: true });
    e.preventDefault();
    await service
      .post("boards/finish/" + this.state.selectedId)
      .then((res) => {
        notification["success"]({
          message: lang.handle('Placa terminada'),

        });
        this.setState({ botonDisabled: true });
        this.Pfetch();
      })
      .catch((error) => {
        notification["error"]({
          message: lang.handle('No se ha podido terminar'),

        });
      });
  };

  render() {
    const { lang } = this.state;
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          botonDisabled: false,
          selectedId: selectedRows[0].ki_id,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        name: record.name,
      }),
    };
    const columns = [
      {
        title: 'Id',
        dataIndex: "ki_id",
        ...this.getColumnSearchProps("ki_id", this.props.lang),
      },
      {
        title: lang.handle('Pedido'),
        dataIndex: "order_id",
        ...this.getColumnSearchProps("order_id", lang),
        //key:'_id',
      },
      {
        title: lang.handle('Instalacion'),
        dataIndex: "name",
        ...this.getColumnSearchProps("name", lang),
      },
      {
        title: lang.handle('Organización'),
        dataIndex: "tenant_name",
        filters: this.state.tenantsFilter,
        onFilter: (value, record) => record.tenant_uuid === value,
      },
      {
        title: lang.handle('Cliente final'),
        dataIndex: "final_client",
        ...this.getColumnSearchProps("final_client", lang),
      },
      {
        title: lang.handle('UUID'),
        dataIndex: "uuid",
        ...this.getColumnSearchProps("uuid", lang),
      },
      {
        title: lang.handle('Número de serie'),
        dataIndex: "serial_number",
        ...this.getColumnSearchProps("serial_number", lang),
      },
      {
        title: lang.handle('Orden de trabajo'),
        dataIndex: "work_order",
        ...this.getColumnSearchProps("work_order", lang),
      },
      {
        title: lang.handle('Fecha fabricación'),
        dataIndex: "date",
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
        sortDirections: ["descend", "ascend"],
        render: function (data) {
          var d = new Date(data);
          if (d !== "Invalid Date") {
            data = moment(d).format('DD-MM-YYYY');
          }
          return data;
        },
      },
      {
        title: "MAC",
        dataIndex: "mac",
        ...this.getColumnSearchProps("mac", lang),
      },
      {
        title: lang.handle('Modelo'),
        dataIndex: "model",
        filters: [
          { text: "Kibox0", value: "Kibox0" },
          { text: "Kibox1", value: "Kibox1" },
          { text: "Kibox1A", value: "Kibox1A" },
          { text: "Kibox1E", value: "Kibox1E" },
          { text: "Kibox2", value: "Kibox2" },
          { text: "Kibox2A", value: "Kibox2A" },
          { text: "Kibox4", value: "Kibox4" },
          { text: "Kibox8", value: "Kibox8" },
          { text: "Kibox-Lite", value: "Kibox-Lite" },
          { text: "Kibox-CAN", value: "Kibox-CAN" },
        ],
        onFilter: (value, record) =>
          record.model && record.model.toLowerCase() === value.toLowerCase(),
      },
      {
        title: lang.handle('Cuadro'),
        dataIndex: "panel",
        filters: [
          { text: lang.handle('No'), value: 0 },
          { text: lang.handle('Sí'), value: 1 },
        ],
        onFilter: (value, record) =>
          (record.panel || record.panel === 0) && record.panel === value,
      },
      {
        title: lang.handle('Cable') + " (m)",
        dataIndex: "cable",
      },
      {
        title: "Kiwi",
        dataIndex: "kiwi",
      },
      {
        title: "ICC",
        dataIndex: "icc",
        ...this.getColumnSearchProps("icc", lang),
      },
      {
        title: lang.handle('Opcionales'),
        dataIndex: "optionals",
        ...this.getColumnSearchProps("optionals", lang),
      },
      {
        title: lang.handle('Notas'),
        dataIndex: "notes",
      },
    ];
    const { datos, loading } = this.state;

    let selection = null;
    if (hasPrivilege('assign_finish')) {
      selection =
      {
        type: 'radio',
        ...rowSelection,
      };
    }

    return (
      <div>
        <DataTable
          rowSelection={selection}
          columns={columns}
          rowKey="ki_id"
          dataSource={datos}
          loading={loading}
        />
        <div className="button-panel">
          <Space>
            {hasPrivilege('assign_finish') && <Button
              type="primary"
              icon={<FlagOutlined />}
              disabled={this.state.botonDisabled}
              onClick={this.finishBoard}
            >
              {lang.handle('Terminar')}
            </Button>}
          </Space>
        </div>
      </div>
    );
  }
}
export default withLocale(KiboxAssignedList);
